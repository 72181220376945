.MuiDataGrid-columnHeaderTitle {
  font-weight: 900 !important;
  color: #5a6a85;
}

.MuiButton-text {
  color: #cd0573 !important;
  font-weight: bold !important;
}

.MuiButton-textDark {
  color: #5a6a85 !important;
  font-weight: bold !important;
}

.row-color {
  color: #5a6a85;
}

.row-color:nth-of-type(odd) {
  background-color: #eaeff4;
}

.row-color:nth-of-type(even) {
  background-color: #ffffff;
}

.custom-btn-font {
  font-size: 16px;
}

button[class*="materialui-daterange-picker-makeStyles-filled"]
  .MuiTypography-colorTextPrimary {
  color: #cd0573 !important;
}

div[class*="materialui-daterange-picker-MuiSelect-select"],
div[class*="materialui-daterange-picker-MuiSelect-selectMenu"] {
  color: #cd0573 !important;
}
button[class*="materialui-daterange-picker-makeStyles-outlined"] {
  border-color: #cd0573 !important;
}

button[class*="materialui-daterange-picker-makeStyles-filled"] {
  background-color: #cd0573 !important;
}
.pborder .MuiOutlinedInput-root fieldset {
  border-color: #dfe5ef !important;
  color: #949eb0 !important;
}

.pborder .MuiOutlinedInput-root:hover fieldset,
.pborder .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #cd0573 !important;
  color: #5a6a85 !important;
}

.custom-swal-confirm-button-success {
  border-radius: 20px;
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  background-color: #a5dc86 !important;
  border-radius: 20px !important;
  width: 70px !important;
}

.custom-swal-confirm-button-error {
  border-radius: 20px;
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  background-color: #f27474 !important;
  border-radius: 20px !important;
  width: 70px !important;
}
.custom-swal-confirm-button-warning {
  border-radius: 20px;
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  background-color: #f8bb86 !important;
  border-radius: 20px !important;
  width: 70px !important;
}
.custom-swal-confirm-buttontext-error {
  border-radius: 20px;
  position: absolute !important;
  bottom: 20px;
  right: 20px;
  background-color: #f27474 !important;
  border-radius: 20px !important;
  width: 200px !important;
}
.custom-swal-confirm-buttontext-cancel {
  border-radius: 20px;
  position: absolute !important;
  bottom: 20px;
  left: 20px;
  background-color: #696969 !important;
  border-radius: 20px !important;
  width: 200px !important;
}

.swal2-popup {
  border-radius: 30px !important;
  z-index: 9999 !important;
}

.swal2-container {
  z-index: 9999 !important;
}

.swal2-title {
  font-size: 24px !important;
  margin-bottom: 30px;
}

.swal-text {
  font-size: 20px !important;
  margin-bottom: 60px !important;
}
.mi-popup-class {
  z-index: 9999;
}
.sidebar-nav ul .sidebar-item .sidebar-link:hover {
  color: #e6007e;
}

.newTicket-btn {
  position: fixed;
  bottom: 20px;
  right: 70px;
  z-index: 99;
}

.btn-limpiar {
  position: fixed;
  bottom: 1px;
  right: 200px;
  z-index: 99;
}

.seleccionado {
  background-color: #EAEFF4;
  color: #e6007e;
}

.campo-requerido {
  border-color: #d80000 !important;    
}

.campo-requerido ~ label {
  color: #d80000 !important;    }

